const config = {
    global: {
        logOut: 'Exit',
        changePassword: 'Change the password'
    },
    workbench: {
        khxx: 'Customer',
        xptj: 'New product',
        yx: "Email",
        sc: "Market",
        dz: "Address",
        ndmb: "Annual target",
        ywcxse: "Sales completed",
        mbwczb: "Percentage of targets achieved",
        zsddje: "Amount of orders in hand",
        dhkje: "Amount to be paid back",
        tp: "Image",
        bcxh: "Factory model",
        zxgg: "Packing specifications",
        mj: "Face price",
        cpxq: "Product details",
        fydje: "Shipping order amount",
        yhkje: "Paid back amount",
        wskje: "Uncollected amount",
        hkr: "Payment date",
    },
    menu: {
        gzt: 'Worktop',
        dccp: 'Dongcheng product',
        dccpLpj: 'Spare parts',
        dccpZj: 'Complete machines',
        dccpZdz: 'Armatures and stators',
        dccpFj: 'Accessories',
        dccpSdgj: 'Hand tools',
        ddzx: 'Order center',
        ddzxygd: 'Pre-order',
        ddzxxdjl: 'Order history',
        ddzxdd: 'Orders in hand',
        ddzxfyd: 'Shipment orders',
        dccpZjZl: 'DC TOOLS',
        dccpZjJl: 'AC TOOLS',
        dccpQt: 'Other',
    },
    detail: {
        cpcs: "PRODUCT PARAMETERS",
        spxq: "PRODUCT DETAILS",
        zxgg: "PACKING SPECIFICATION",
        cpsl: "QUANTITY",
        jrgwc: "ADD TO SHOPPING CART",
        srgmsl: "Please enter the purchase quantity",
        qxzgg: "Please select the specification",
        xymoq: "Current order quantity is less than the minimum order quantity",
        xdts: "Tips for ordering",
        dqxdbzx: "Current order quantity not in full cartons",
        qsrxdsl: "Please enter the order quantity",
        ywpm: "English product name",
        pp: "Brand",
        zg: "In total",
        xzgg: "select the specification",
        item: "item",
        yes: "YES",
        no: "NO",
        dy: "Voltage"
    },
    Order: {
        ddzxygdxq: "Pre-order details"
    },
    Xdjl: {
        dh: "Order number",
        xdrq: "Order date",
        sodf: "Sold-to party",
        shdf: "Ship-to party",
        ddje: "Order amount",
        mz: "Gross weight",
        jz: "Net weight",
        tj: "Volume",
        xdjl: "Order history",
        bj: "Edit",
        xdjlxq: "Order history details",
        xdjlxg: "Order history modification",
        mdg: "Port of destination",
        ddmx: "Order details",
        hjxs: "Total number of cartons",
        hjmz: "Total gross weight",
        hjjz: "Total net weight",
        hjtj: "Total volume",
        djbm: "Pricing code",
        cpbm: "Product code",
        cpmc: "Product name",
        khxh: "Customer model",
        sl: "Quantity",
        ygdj: "Estimated unit price",
        ygje: "Estimated amount",
        cz: "Operation",
        sfqrsc: "Confirmed to delete?",
        scsp: "Delete the product",
        save: "Save",
        cancel: "Cancel",
        yjzgr: "Expected consign date",
    },
    Ygd: {
        ygdh: "Pre-order number",
        ddzt: "Order status",
        dywqr: "Waiting for business confirmation",
        yqr: "Confirmed",
        qwjq: "Expected delivery date",
        dhtqr: "Waiting for contract confirmation",
        fyz: "In transit",
        ygdrq: "Pre-order date",
        scht: "Upload the contract",
        ckzxht: "View the latest contract",
        ckzxbcxy: "View the latest supplemental agreement",
        ygdxq: "Pre-order details",
        ygdscht: "Upload the pre-order contract",
        gsmc: "Company name",
        khmc: "Customer name",
        ddrq: "Order date",
        zyg: "Port of shipment",
        mysy: "Trade terms",
        zytj: "Shipment Conditions",
        yhzh: "Bank account",
        fkfs: "Payment method",
        shfs: "Receiving method",
        xs: "Number of cartons",
        qrht: "Confirm the contract",
        htmc: "Contract name",
        htlx: "Contract type",
        xzdz: "Download address",
        xz: "Download",
        tj: "Submit",
        ddsl: "Order quantity",
        ygdhh: "Row number of the pre-order",
        jq: "Delivery date",
    },
    Zsdd: {
        zsddgl: "Management of orders in hand",
        zsddxq: "Details of orders in hand",
    },
    Fyd: {
        fydh: "Shipment order No.",
        fyg: "Port of embarkation",
        fl: "Rebates",
        hyf: "Sea freight",
        zxb: "Sinosure",
        fyje: "Shipment amount",
        zgr: "Consign date",
        tdr: "B/L date",
        tdh: "B/L No.",
        dgr: "Time of arrival",
        fydgl: "Shipment order management",
        ddh: "Order No.",
        fydxq: "Shipment order details",
        fydxz: "Shipment order added",
        ckfyht: "View the shipment contract",
        fydmx: "Shipment order details",
        khjc: "Customer's abbreviation",
        ddwc: "Order completed",
        bz: "Currency",
        hthq: "Signing back of the contract",
        fph: "Invoice No"
    },
    Gwc: {
        gwc: "Cart",
        ddbh: "Order No.",
        ckdh: "View orders",
        shdh: "Delivery number",
        qk: "Debt",
        ddbz: "Order remarks",
        gwmx: "Shopping details",
        dr: "Import",
        spmc: "Product name",
        dw: "Unit",
        moq: "moq",
        mj: "Face price",
        slzj: "Total quantity",
        spzj: "Total price",
        syb: "Previous step",
        qd: "Confirm",
        yxz: "Selected",
        x: "Sum",
        qrgwc: "Confirm the cart",
        qrdd: "Confirm the order",
        tjwc: "Submission completed",
        qxzxdcp: "Please select the product to be ordered",
        qxzsdf: "Please select the party to be served",
        qxzbz: "Please select the currency",
        qxzmdg: "Please select the port of destination",
    },
    Configuration: {
        search: "Search",
        reset: "Reset",
        xd: "Place an order",
        mbxz: "Download templates",
        ts: "Tips",
        cxjg: "Inquiry result",
        khzt: "EXW",
        wtfh: "Commissioned shipment",
        dsfkd: "Third party express",
        wlty: "Logistic consignment",
        hydl: "Freight forwarding",
        zzjc: "Ride-hailing by customer",
        dc: "Export",
        dlcg: "Login successfully",
        qxzcp: "Please select the product",
        khxdh: "Customer order number",
        xdsl: "Order quantity",
        qcxdl: "Please log in again",
        cpdrsb: "Product importing failed",
        fysl: "Shipment quantity",
        mxmz: "GW",
        mzjz: "NW",
        mxtj: "CBM",
        yuan: "Coin",
        yfsl: "Quantity shipped",
        wfsl: "Quantity not shipped",
        hylddms: "Welcome to DMS",
        yhm: "Username",
        mm: "Password",
        dl: "Login in",
        sq: "Hide",
        zk: "View more",
        more: "More",
        add: "Add",
        sc: "Delete",
        bc: "Save",
        qx: "Cancel",
        bj: "Edit",
        sfscch: "Detele this line",
        qsr: "Please enter",
        qxz: "Please select",
        xz: "Add a new one",
        mmcd: "The new password cannot be less than 6 digits long",
        qrmmyz: "The new password does not match the confirmed one, please enter it again!",
        dlqr: "You are not logged in, please visit after logging in!\n",
        qxzpp: "Please select the brand",
        jsfp: "Simplified invoice",
        xxfp: "Detailed invoice",
        jsxd: "Simplified packing list",
        xxxd: "Detailed packing list",
        fyht: "Shipment contract",
        xsfp: "Proforma invoice",
        xmm: "New Password",
        qrxmm: "Confirm new password",
        btopbz: "表头备注",
        bodytbz: "表体备注",
        ggp: "是否包含广告品",
        qckby: "去除空白页",
        dcbz: "导出备注",
        qr: "确认",
        bp:"Standard"
    },
}

export default config
